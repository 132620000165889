import { useEffect } from 'react';
import Head from 'next/head';
import styles from '../styles/Home.module.css';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { loginRequest } from '../helpers/auth-config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button } from 'antd';

export default function Home() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      //instance.loginRedirect(loginRequest);
    }
    //login();

    /*if (isAuthenticated) {
      var request = {
        scopes: [],
      };

      instance
        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          console.log(tokenResponse);
          // Do something with the tokenResponse
        })
        .catch((error) => {
          if (error /*instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(request);
          }
        });
    }*/
  }, [isAuthenticated]);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>PAM</title>
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <AuthenticatedTemplate>
        <Button onClick={handleLogout}>Logout</Button>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Button onClick={handleLogin}>Login</Button>
      </UnauthenticatedTemplate>
    </div>
  );
}
